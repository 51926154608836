import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@shared/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public dialog: MatDialog,
    private router: Router
  ) { }
  loginForm: FormGroup;
  loading = false;
  errorType = 'custom-danger';
  loginResponse: any;
  isCheckboxChecked: boolean = false;

  fieldTextType = false;
  eye = true;
  @ViewChild('lform') loginFormDirective: any;

  @ViewChild('splashDialog', { static: false }) splashDialog: TemplateRef<any>;
  splashDialogRef: MatDialogRef<any, any>;

  formErrors: any = {
    email: '',
    password: '',
  };

  validationMessages: any = {
    password: {
      required: 'Password is required.',
      minlength: 'Password must be at least 4 characters long.',
      maxlength: 'Password cannot be more than 40 characters long.',
    },
    email: {
      required: 'Email is required.',
      email: 'Please check your email address, it does not look correct.',
    },
  };

  createForm(): any {
    this.loginForm = this.formBuilder.group({
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(40),
        ],
      ],
      email: ['', [Validators.required, Validators.email]],
    });
    this.loginForm.valueChanges.subscribe(data => {
      this.onValueChanged(data);
    });
  }

  togglePassword(): any {
    this.fieldTextType = !this.fieldTextType;
    this.eye = !this.eye;
  }
  setChecked(event: any) {
    this.isCheckboxChecked = event.target.checked;
  }
  inputErrorClass(): boolean {
    const isError = this.formErrors.password.length;
    return isError;
  }

  onValueChanged(data?: any): any {
    if (!this.loginForm) {
      return;
    }
    const form = this.loginForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  ngOnInit(): void {
    this.createForm();
  }

  onSubmit(): any {
    const loginData = this.loginForm.value;
    this.loading = true;
    this.authService.login(loginData).subscribe(
      (response: any) => {
        this.loginFormDirective.resetForm();
        this.loginForm.reset({
          password: '',
          email: '',
        });
        
        this.loginResponse = response.message;
        this.loading = false;
        if (response.data.tosContent) {
          this.authService.logout(true).subscribe(() => {
            localStorage.removeItem('token');
            this.openSplashDialog(response.data.tosContent).subscribe(() => {
              if (this.isCheckboxChecked) {
                this.authService
                  .login(loginData)
                  .subscribe(() => this.router.navigate(['/home']));
              }
            });
          });
        } else {
          this.router.navigate(['/home']);
        }
      },
      (error: any) => {
        this.loading = false;
        this.loginResponse = 'Invalid Username or Password'
        if (error.error) {
          if (error.error.message)
            this.loginResponse = error.error.message;
        } else if (error.message) {
          this.loginResponse = error.message;
        }
      }
    );
  }
  openSplashDialog(content?: string) {
    this.dialog.closeAll();
    this.splashDialogRef = this.dialog.open(this.splashDialog, {
      disableClose: true,
      maxWidth: '80vw',
      panelClass: ['form-dialog'],
      data: {
        content,
      },
    });
    return this.splashDialogRef.afterClosed();
  }
}
