<div class="login-page">
    <!-- Top-left corner text -->
    <div class="top-left-corner">
        <p>NorthStandard Account</p>
    </div>

    <!-- Main content -->
    <div class="forgot-password-page central-column-page">

        <div class="forgot-password-page-wrapper">
            <div title="NorthStandard" class="company-logo" tabindex="-1">
                <img src="assets/images/Combined_shape.png">
            </div>
            <h1>Set your password?</h1>

            <p class="success-message">To set your password, please enter new password.</p>
            <!-- <form (keydown.enter)="$event.preventDefault()" [formGroup]="setForm" #rform="ngForm"
                (ngSubmit)="onSubmit()">
                <div class="input">
                    <label>Email</label>
                    <input type="text" formControlName="email" id="email">
                    <div class="form-text error text-danger" *ngIf="formErrors.email">
                        {{formErrors.email}}
                    </div>
                </div>
                <div class="forget-pass-cont">
                    <p>You will receive a password reset link by email</p>
                </div>
                <div class="forget-btns">
                    <button class="cursor-pointer yes-btn" [disabled]="setForm.invalid" type="submit"
                        [ngClass]="setForm.invalid ? 'btn-disabled' : ''">Yes</button>
                    <button class="no-btn" type="button" [routerLink]="['/']">No</button>
                </div>
            </form> -->
            <form id="forgot-password-form" novalidate="true" (keydown.enter)="$event.preventDefault()"
                [formGroup]="setForm" #rform="ngForm" (ngSubmit)="onSubmit()" class="form-group"
                [ngClass]="{'error': formErrors.email}">
                <div class="form-group" [ngClass]="{'error': formErrors.password}">
                    <label for="password">New Password</label>
                    <div class="password-container">
                        <input [type]="PasswordFieldTextType ? 'text' : 'password'" id="password" name="password"
                            formControlName="password" placeholder="Enter new password">
                        <button type="button" (click)="togglePassword()" class="toggle-password">
                            <img [src]="eye1 ? 'assets/images/eye-icon.svg' : 'assets/images/eye-slash.svg'"
                                alt="Toggle visibility">
                        </button>
                    </div>
                    <div class="error-message" *ngIf="formErrors.password">{{ formErrors.password }}</div>
                </div>
                <br />
                <div class="error-message" *ngIf="formErrors.email">{{formErrors.password}}</div>
                <div class="form-group" [ngClass]="{'error': formErrors.confirmPassword}">
                    <label for="confirmPassword">Confirm Password</label>
                    <div class="password-container">
                        <input [type]="ConfirmPasswordFieldTextType ? 'text' : 'password'" id="confirmPassword" name="confirmPassword"
                            formControlName="confirmPassword" placeholder="Confirm new password">
                        <button type="button" (click)="toggleConfirmPassword()" class="toggle-password">
                            <img [src]="eye2 ? 'assets/images/eye-icon.svg' : 'assets/images/eye-slash.svg'"
                                alt="Toggle visibility">
                        </button>
                    </div>
                    <div class="error-message" *ngIf="formErrors.confirmPassword">{{ formErrors.confirmPassword }}</div>
                </div>
                <p>
                    <button id="forgot-password-btn" [disabled]="setForm.invalid" type="submit"
                        [ngClass]="setForm.invalid ? 'btn-disabled' : ''"
                        data-requires-valid="#EmailAddress">Confirm</button>
                </p>
            </form>
        </div>
    </div>

    <footer class="page-footer footer-variation-default " title="Page footer">
        <p class="footer-copywrite-msg">© 2024 NorthStandard</p>
        <p class="footer-build-msg">Build <span>1.2.0.20242 (UAT)</span></p>
        <a href="https://north-standard.com" class="footer-company-logo" title="Visit north-standard.com"></a>
    </footer>
</div>
