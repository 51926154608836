<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop: true }"></ngx-loading>

<div class="login-page">
    <!-- Top-left corner text -->
    <div class="top-left-corner">
        <p>NorthStandard Account</p>
    </div>

    <!-- Main content -->
    <div class="central-column-page">
        <app-http-message [type]="errorType" [data]="loginResponse" class="alert-successful"></app-http-message>
        <div class="split-columns">
            <!-- Left Section: Logo and Text -->
            <div class="left">
                <div alt="Logo" class="company-logo">
                    <img src="assets/images/Combined_shape.png">
                </div>
                <p id="login-page-message-web">Login to access your NorthStandard services.</p>
            </div>
            <!-- Right Section: Login Form -->
            <form [formGroup]="loginForm" #lform="ngForm" (ngSubmit)="onSubmit()" class="right login-form">
                <div class="form-group" [ngClass]="{'error': formErrors.email}">
                    <label for="email">Email (required)</label>
                    <input type="email" id="email" name="email" formControlName="email" placeholder="Enter your email">
                    <div class="error-message" *ngIf="formErrors.email">{{ formErrors.email }}</div>
                </div>
                <div class="form-group" [ngClass]="{'error': formErrors.password}">
                    <label for="password">Password (required)</label>
                    <div class="password-container">
                        <input [type]="fieldTextType ? 'text' : 'password'" id="password" name="password"
                            formControlName="password" placeholder="Enter your password">
                        <button type="button" (click)="togglePassword()" class="toggle-password">
                            <img [src]="eye ? 'assets/images/eye-icon.svg' : 'assets/images/eye-slash.svg'"
                                alt="Toggle visibility">
                        </button>
                    </div>
                    <div class="error-message" *ngIf="formErrors.password">{{ formErrors.password }}</div>
                </div>
                <!-- <div class="remember-me-wrapper">
                    <input title="Remember me on this browser" id="remember-me" type="checkbox" data-val="true"
                        data-val-required="The RememberLogin field is required." name="Input.RememberLogin" value="true"
                        data-vds-is-checked="false" class="interacted">
                    <label for="remember-me" class="inline">Remember me on this browser</label>
                </div> -->
                <button type="submit" class="btn-submit" [disabled]="loginForm.invalid">Log in</button>
                <div class="additional-links">
                    <a [routerLink]="['/forgot-password']">Forgot your password?</a>
                    <!-- <p>Not yet registered? Please register <a [routerLink]="['/register']">here</a>.</p> -->
                </div>
            </form>
        </div>
    </div>

    <footer class="page-footer footer-variation-default " title="Page footer">
        <p class="footer-copywrite-msg">© 2024 NorthStandard</p>
        <p class="footer-build-msg">Build <span>1.2.0.20242 (UAT)</span></p>
        <a href="https://north-standard.com" class="footer-company-logo" title="Visit north-standard.com">
            <img src="assets/images/Combined_shape.png">
        </a>
    </footer>
</div>

<ng-template #splashDialog let-data>
    <div class="dialog-content-wrapper">
        <h4 align="center" class="mb-3">
            Terms & Conditions
        </h4>
        <div mat-dialog-content class="overflow-y-auto">
            <div *ngIf="data.content" [innerHTML]="data.content" class="initHTML"></div>
        </div>
        <div class="mt-2 d-flex gap-2 mx-4">
            <input id="termsCheckbox" class="" type="checkbox" [checked]="isCheckboxChecked"
                (change)="setChecked($event)" />
            <label for="termsCheckbox">
                By clicking agree and continue, I accept the terms and conditions to use this application.
            </label>
        </div>
        <div class="submit-answer mx-auto mt-4 text-center">
            <button type="button" [disabled]="!isCheckboxChecked" (click)="dialog.closeAll()">Accept</button>
        </div>
    </div>
</ng-template>